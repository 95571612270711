import { useState } from "react"
import jsPDF from 'jspdf';
import './App.css';

function App() {
  const [list, setList] = useState([]);
  const [input, setInput] = useState("");

  const addTodo = (todo) => {
    const newTodo = {
      id: Math.random(),
      todo: todo
    }
    // Ajouter une tâche
    setList([...list, newTodo])

    // Clear input box
    setInput("");
  }

  const deleteTodo = (id) => {
    // Filter out todo with the id
    const newList = list.filter((todo) => todo.id !== id);

    setList(newList);
  }

  const generatePDF = () => {
    const doc = new jsPDF();
    const date = new Date().toLocaleDateString('fr-FR'); // Utilisez le format de date français

    // Définissez des marges
    const marginLeft = 20;
    const marginTop = 25;

    // Personnalisez le titre avec la date, en tenant compte des marges
    doc.setFontSize(18);
    doc.text(`Ma TO DO LIST du ${date}`, marginLeft, marginTop);

    let height = marginTop + 15; // Commencez la liste des tâches sous le titre

    list.forEach((item, index) => {
      // Dessinez une case à cocher vide en prenant en compte la marge gauche
      doc.rect(marginLeft, height - 3, 5, 5);

      // Ajoutez le texte de la tâche en prenant en compte la marge gauche
      doc.setFontSize(12);
      doc.text(item.todo, marginLeft + 10, height);

      height += 10; // Espace entre les tâches
    });

    // Sauvegardez le PDF avec un nom de fichier qui comprend la date
    doc.save(`Ma_ToDoList_du_${date.replace(/\//g, '-')}.pdf`); // Remplacez les slashs par des tirets pour un nom de fichier valide
  }

  return (
    <div className="mainDiv">
      <div className="todoDiv">
        <h1 className="title"><span className="myText">My</span> To Do List</h1>
        <div className="mainDivTodoList">
          <div className="divInput">
            <input
              className="input"
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
            <button className="buttonAdd" onClick={() => addTodo(input)}>Ajouter</button>
          </div>
          {list.map((todo) => (

            <div className="divTodoList" key={todo.id}>
              <div className="todoList">&#x279E; {todo.todo}</div>
              <div><div className="buttonSupp" onClick={() => deleteTodo(todo.id)}>&#x274C;</div></div>
            </div>

          ))}

        </div>
        <div className="divButtonImp">
          <button className="buttonImp" onClick={generatePDF}>Imprimer en PDF</button>
        </div>
      </div>
    </div>
  );
}

export default App;
